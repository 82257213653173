




































































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'

// Store
import { userNamespace } from '@store/user'

// Interfaces
import { Project } from '@store/projects/interfaces'
import { UserActionTypes } from '@store/user/Types'

@Component({
  name: 'ProjectCard',
})
export default class ProjectCardComponent extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  private project!: Project

  @userNamespace.Action(UserActionTypes.A_CHANGE_PROJECT)
  private changeProject!: (projectId: number) => Promise<void>


  public get team(): string {
    if (this.project.users) {
      return this.project.users.map(t => t).join(' • ')
    } else {
      return ''
    }
  }

  public get newDocuments(): string {
    if (this.project.newDocuments) {
      return `+${this.project.newDocuments}`
    } else {
      return 'Нет'
    }
  }

  private handleSelectProject(projectId: number) {
    this.changeProject(projectId)
    this.$router.push('/')
  }
}
